import search from "../../assets/search.svg";
import React, { useState,useEffect, useRef } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAddress} from "../../store/stringSlice"

// Your API key here
const GOOGLE_MAPS_API_KEY = 'AIzaSyBOuPOv0Ztq0yOARwDuxh8GNikEULFvGuo';

const Home = () => {
  const [activeButton, setActiveButton] = useState("Buy");
  const [dynamicPlaceholder, setDynamicPlaceholder] = useState("Enter a location");
  const autocompleteRef = useRef(null);
   const navigate = useNavigate();
   const dispatch = useDispatch();
  const address = useSelector((state) => state.address);


  const handleButtonClick = (button) => {
    setActiveButton(button);
    // Update the placeholder dynamically based on the button clicked
    setDynamicPlaceholder(`Search for ${button.toLowerCase()} options`);
  };

  const renderPlaceHolder = () => {
    if (activeButton === "Analyze" || activeButton === "Buy") {
      setDynamicPlaceholder("Enter any city, ZIP code, or address");
    } else {
      setDynamicPlaceholder("Enter your address");
    }
  };
  
  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    const formattedAddress = place.formatted_address;
    dispatch(setAddress(formattedAddress));
  };
  

  useEffect(() => {
    renderPlaceHolder();
  }, [activeButton]);

  const handleSearch = () => {
    if (activeButton === "Finance") {
      navigate('/cashoutform');
    } else if (activeButton === "Buy") {
      navigate('/buy');
    } else if (activeButton === "Sell") {
      navigate('/seller-form');
    }
  };

  const handleLanding = () => {
    if (activeButton === "Finance") {
      navigate('/cashout');
    } else if (activeButton === "Buy") {
      navigate('/buyer');
    } else if (activeButton === "Sell") {
      navigate('/sell');
    }
  };

  

  return (
    <div className="relative flex  justify-center h-[900px] sm:h-[1050px] text-white bg-home-hero-mobile-bg sm:bg-home-hero-bg bg-no-repeat bg-center bg-cover">
      <div className="z-10 sm:px-6 lg:px-8 my-[90px] sm:mt-[120px]">
        <h1 className="text-[30px] leading-[40px] w-[314px] sm:w-auto lg:w-[1006px] mx-auto sm:mx-0  mt-[150px] text-center sm:text-5xl md:text-[64px] sm:leading-[82.4px] font-light">
          Achieve Your Home Ownership Goals With&nbsp;<span className="text-[#ED5272] font-bold">Home.LLC</span>
        </h1>

        <div className="w-[376px] sm:mx-0 sm:w-auto mt-[212px] sm:mt-28">
          <div className="flex justify-center items-center space-x-4 mb-[56px] sm:mb-[46px] text-medium px-[20px]">
            {["Buy", "Finance", "Sell"].map((button) => (
              <button
                key={button}
                onClick={() => handleButtonClick(button)}
                className={`w-[98px] h-[30px] sm:h-auto sm:w-auto sm:px-6 pb-3 sm:pb-3 sm:py-2 text-[16px] font-medium sm:text-xl bg-transparent hover:text-pink-500 ${
                  activeButton === button
                    ? "border-b-2 border-white text-[#ED5272]"
                    : "text-white"
                }`}>
                {button}
              </button>
            ))}
          </div>
          <div className="mx-0 pb-[10px] border-b flex gap-10 sm:justify-between items-center">
          <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["places"]}>
  <Autocomplete
    onLoad={(autocomplete) => {
      autocompleteRef.current = autocomplete;
    }}
    onPlaceChanged={handlePlaceSelect}
  >
    <input
      type="text"
      placeholder={dynamicPlaceholder}
      className="w-full sm:w-[900px] h-10 sm:h-12 px-4 rounded-lg outline-none bg-transparent font-quickSand text-base sm:text-xl placeholder-opacity-70 focus:placeholder-opacity-40 transition duration-200 ease-in-out focus:border-blue-500"
      style={{ boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
      value={address} // Bind the input value to the Redux state
      onChange={(e) => {
        const value = e.target.value;
        dispatch(setAddress(value));
      }}
      
    />
  </Autocomplete>
</LoadScript>

            <div className="flex items-center gap-2 sm:gap-4">
              <img
                src={search}
                alt="search-icon"
                className="cursor-pointer w-[13px] h-[13px] sm:w-auto sm:h-auto"
                onClick={handleSearch}
              />
              <span className="text-white font-semibold font-quickSand text-[14px] leading-[18px] sm:text-[22px] sm:leading-[28px] cursor-pointer"
              onClick={handleSearch}>
                Check
              </span>
            </div>
          </div>

          <h5 className="text-center my-7 font-[275] text-[20px] leading-[30px] sm:text-[36px] sm:leading-[54px]">
            OR
          </h5>

          <div className="w-[200px] h-[46px] sm:w-[319px] sm:h-[70px] p-1 sm:p-2.5 sm:gap-2.5 mx-auto my-4">
            <button
              className="w-full h-full bg-white sm:bg-transparent text-black sm:text-white font-semibold sm:font-normal
             border-white border-[1.5px] rounded-[70px] text-sm sm:text-xl sm:hover:bg-[#ED5272]
             sm:hover:text-white sm:hover:border-none transition duration-300 ease-in-out"
             onClick={handleLanding}>
              
             Know More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
