import React, { useState, useRef } from "react";
import ProgressIndicator from "./ProgressIndicator";
import Navigation from "./Navigation";
import { useEffect } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import UserDetailsModal from "./UserDetailsModal";
import { setAddress } from "../../store/stringSlice";
import { useDispatch, useSelector } from 'react-redux';

const libraries = ["places"];


const CalculatorFormBuyer = () => {
  const steps = [
    "When are you planning to buy",
    "Location Check",
    "Your Home Preferences",
    "Property Details",
    "pd1",
    "pd2",
    "Eligibility Result",
  ];

  const steps1 = [
    "When are you planning to buy",
    "Location Check",
    "Your Home Preferences",
  ];

  const GOOGLE_MAPS_API_KEY = "AIzaSyBOuPOv0Ztq0yOARwDuxh8GNikEULFvGuo";
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [activeSteps, setActiveSteps] = useState(steps);
  const [userResponses, setUserResponses] = useState({
    purchaseTiming: "", // Time frame for buying the home
    preapproved: "",
    address: "", // Location where they plan to buy
    important: [], // Array to store multiple selections
    otherImportant: "",
    bedrooms: "", // Number of bedrooms
    bathrooms: "", // Number of bathrooms
    sqft: 0,
    homeValue: 0,
    name: "", // Added name field
    email: "", // Added email field
    phone: "", // Square footage
    propertyConditions: [], // Condition of the property
    propertyTypes: [], // Type of property
    qualifiedAmount: 7500, // Hardcoded cashback value for demonstration
    what: "",
    hesitations: [], // State for hesitations
    otherHesitations: "",
    Date: new Date().toLocaleDateString("en-US"),
  });

  const dispatch = useDispatch();
  const address = useSelector(state => state.address);

  // Validation functions for each step
  const validations = {
    0: () => userResponses.purchaseTiming && userResponses.preapproved,
    1: () => userResponses.address || userResponses.hesitations,
    2: () => userResponses.important || userResponses.what,
    3: () =>
      userResponses.bedrooms && userResponses.bathrooms && userResponses.sqft,
    4: () => userResponses.propertyConditions.length > 0,
    5: () =>
      userResponses.propertyTypes.length > 0 &&
      userResponses.priceMin !== "" &&
      userResponses.priceMax !== "",
    6: () => userResponses.homeValue > 0,
  };

  // Sync the local state with the Redux state
  useEffect(() => {
    setUserResponses(prevResponses => ({
      ...prevResponses,
      address: address
    }));
  }, [address]);

  // Function to check if the current step is valid
  const isValidStep = (step) => {
    return validations[step]();
  };

  useEffect(() => {
    setIsNextEnabled(isValidStep(currentStep));
  }, [userResponses, currentStep]);

  const stepImages = [
    "https://media.istockphoto.com/id/1019219898/photo/real-estate-or-property-investment-home-mortgage-loan-rate-saving-money-for-retirement.jpg?s=612x612&w=0&k=20&c=8NEnyouCL-5HsqRsEFUkxc0J7fkv4ZoPl78zDAtk790=",
    "https://hips.hearstapps.com/hmg-prod/images/edc100123egan-002-6500742f5feb7.jpg",
    "https://eplan.house/application/files/6314/6702/0525/vid-speredy-JD-23274.jpg",
    "https://st4.depositphotos.com/13324256/26353/i/450/depositphotos_263536652-stock-photo-silver-golden-coins-green-leaves.jpg",
    "https://st.hzcdn.com/simgs/pictures/exteriors/contemporary-open-home-clearovations-img~c2f196810890c514_14-4417-1-bd2ff3a.jpg",
    "https://eplan.house/application/files/6314/6702/0525/vid-speredy-JD-23274.jpg",
    "https://hips.hearstapps.com/hmg-prod/images/edc100123egan-002-6500742f5feb7.jpg",
  ];

  const handleSelect = (field, value) => {
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [field]: value,
    }));

    // If the address is being updated, also update the Redux store
    if (field === 'address') {
      dispatch(setAddress(value));
    }
  };

  const handleScheduleCall = () => {
    setShowUserDetailsModal(true);
  };

  const goNext = () => {
    console.log(userResponses);

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }    
  };

  const goBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleDropdownChange = (event, type) => {
    const { value } = event.target;
    handleMultiSelect(type, value);
  };

  const propertyOptions = {
    conditions: ["Good", "Fair", "Poor"],
    types: [
      "Condominium",
      "House",
      "Land",
      "Manufactured",
      "Other",
      "Townhouse",
    ],
  };

  const handleMultiSelect = (field, value) => {
    setUserResponses((prev) => ({
      ...prev,
      [field]: prev[field].includes(value)
        ? prev[field].filter((item) => item !== value)
        : [...prev[field], value],
    }));
  };

  const handleSelectPurchaseTiming = (value) => {
    // Update the purchase timing in state
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      purchaseTiming: value,
    }));

    // Decide whether to show conditional steps
    const showConditionalSteps =
      value === "within_a_year" || value === "someday";
    setUserResponses((prevResponses) => ({
      ...prevResponses,
      showConditionalSteps,
    }));
    // Update active steps based on the showConditionalSteps
    setActiveSteps(showConditionalSteps ? steps1 : steps);
  };

  const autocompleteRef = useRef(null);

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    handleSelect("address", place.formatted_address);
  };

  const handleModalVisibility = async () => {
    setShowUserDetailsModal(false);
    await sendDataToWebhook();
    window.location.href = "/schedule-call-buy";
  };

  const sendDataToWebhook = async () => {
    try {
      const response = await fetch(
        "https://hooks.zapier.com/hooks/catch/10869005/2odbkio/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userResponses),
          mode: "no-cors",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      console.log("Data sent successfully to the webhook.");
    } catch (error) {
      // console.error("Error sending data to the webhook:", error);
    }
  };

  return (
    <div className="flex w-full min-h-screen">
      <div>
        {showUserDetailsModal && (
          <div className="modal-backdrop">
            <div className="modal-dialog">
              <UserDetailsModal
                isOpen={showUserDetailsModal}
                onClose={() => handleModalVisibility(false)}
                userResponses={userResponses}
                updateUserResponses={handleSelect}
              />
            </div>
          </div>
        )}
      </div>

      <div className="w-full md:w-2/3 p-4 overflow-auto">
        <ProgressIndicator steps={activeSteps} currentStep={currentStep} />

        {currentStep === 0 && (
          <div className="bg-white mb-20 p-6 mt-8">
            <div className="flex items-center gap-2 mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                01
              </span>
              <h2 className="text-3xl text-gray-900">
                When are you planning to buy your home?
              </h2>
            </div>
            <form>
              <fieldset>
                <legend className="sr-only">Home Purchase Timing</legend>
                {[
                  { label: "Right Away", value: "right_away" },
                  { label: "Within 3 Months", value: "within_3_months" },
                  { label: "Within a Year", value: "within_a_year" },
                  { label: "Someday", value: "someday" },
                ].map((option, index) => (
                  <label
                    key={index}
                    className="block mt-4 text-lg text-gray-700"
                  >
                    <input
                      type="radio"
                      name="purchaseTiming"
                      value={option.value}
                      className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
                      onChange={(e) =>
                        handleSelectPurchaseTiming(e.target.value)
                      }
                    />
                    <span className="ml-4">{option.label}</span>
                  </label>
                ))}
              </fieldset>
              <fieldset className="grid grid-cols-2 gap-4 mt-8">
                <legend className="text-lg text-gray-900">
                  Are you preapproved?
                </legend>
                {[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                ].map((option, index) => (
                  <label
                    key={index}
                    className="block mt-4 text-lg text-gray-700"
                  >
                    <input
                      type="radio"
                      name="preapproved"
                      value={option.value}
                      className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
                      onChange={(e) =>
                        handleSelect("preapproved", e.target.value)
                      }
                    />
                    <span className="ml-4">{option.label}</span>
                  </label>
                ))}
              </fieldset>
            </form>
          </div>
        )}

        {currentStep === 1 && !userResponses.showConditionalSteps && (
          <div className="bg-white mb-64  p-6 rounded-lg mt-8 ml-4">
            <div className="flex items-center mb-8 gap-2">
              <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
                02
              </span>
              <h2 className="text-3xl text-gray-900">
                Where Are You Planning To Buy?
              </h2>
            </div>
            <p className="text-black">
              This helps us make sure we're available in your area.
            </p>
            <LoadScript
              googleMapsApiKey={GOOGLE_MAPS_API_KEY}
              libraries={libraries}
              loadingElement={<div>Loading...</div>}
              options={{
                defer: true,
                async: true,
              }}
            >
              <Autocomplete
                onLoad={(autocomplete) => {
                  autocompleteRef.current = autocomplete;
                }}
                onPlaceChanged={handlePlaceSelect}
              >
                <input
                  type="text"
                  placeholder="Search your property's city, ZIP code, or address"
                  value={userResponses.address}
                  onChange={(e) => handleSelect("address", e.target.value)}
                  className="w-full px-4 py-2 mt-4 leading-tight text-black bg-transparent shadow-none focus:outline-none focus:bg-transparent placeholder-gray-500"
                  style={{ borderBottom: "3px solid black" }}
                />
              </Autocomplete>
            </LoadScript>
          </div>
        )}
        {currentStep === 1 && userResponses.showConditionalSteps && (
          <div className="bg-white mb-36 p-6 mt-4">
            <div className="flex items-center mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                02
              </span>
              <h2 className="text-3xl text-gray-900">
                What Hesitations Do You Have?
              </h2>
            </div>
            <form>
              <div className="gap-4">
                {[
                  "Stuck in a Long Term Lease",
                  "Betting on a Home Price Crash",
                  "Waiting For Lower Rates",
                  "Still Saving For Down-payment",
                  "Still looking for my 'dream home'",
                  "Not sure about areas to buy",
                ].map((option, index) => (
                  <label
                    key={index}
                    className="block text-lg text-gray-700 my-4 flex items-center"
                  >
                    <input
                      type="checkbox"
                      name="hesitations"
                      value={option}
                      onChange={(e) =>
                        handleMultiSelect("hesitations", e.target.value)
                      }
                      className="form-checkbox h-5 w-5 text-pink-600 focus:ring-pink-500 mr-2"
                    />
                    {option}
                  </label>
                ))}
              </div>
              <div className="mt-4">
                <label className="block text-lg text-gray-900">Others</label>
                <input
                  type="text"
                  className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                  onChange={(e) =>
                    handleSelect("otherHesitations", e.target.value)
                  }
                />
              </div>
            </form>
          </div>
        )}

        {currentStep === 2 && !userResponses.showConditionalSteps && (
          <div className="bg-white mb-20 p-6 mt-4">
            <div className="flex items-center mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                03
              </span>
              <h2 className="text-3xl text-gray-900">
                What Is Important To You?
              </h2>
            </div>
            <form>
              <fieldset className="preferences-grid ">
                <legend className="sr-only">Preferences in Housing</legend>
                {[
                  { label: "Great School", value: "great_school" },
                  { label: "Low Crime", value: "low_crime" },
                  { label: "Easy Commute", value: "easy_commute" },
                  { label: "Low Disaster Risk", value: "low_disaster_risk" },
                  { label: "Big Lot", value: "big_lot" },
                  { label: "High Walkability", value: "high_walkability" },
                  { label: "Others", value: "others" },
                ].map((option, index) => (
                  <label key={index} className="block text-lg text-gray-700">
                    <input
                      type="checkbox"
                      name="important"
                      value={option.value}
                      checked={userResponses.important.includes(option.value)}
                      onChange={(e) => {
                        const newSelection = e.target.value;
                        setUserResponses((prevResponses) => ({
                          ...prevResponses,
                          important: prevResponses.important.includes(
                            newSelection
                          )
                            ? prevResponses.important.filter(
                                (item) => item !== newSelection
                              )
                            : [...prevResponses.important, newSelection],
                        }));
                      }}
                      className="form-checkbox h-5 w-5 text-pink-600 focus:ring-pink-500"
                    />
                    <span className="ml-4">{option.label}</span>
                  </label>
                ))}
              </fieldset>
              {userResponses.important.includes("others") && (
                <div className="mt-4">
                  <label className="block text-lg text-gray-900">
                    Please Specify
                  </label>
                  <input
                    type="text"
                    value={userResponses.otherImportant}
                    onChange={(e) =>
                      setUserResponses({
                        ...userResponses,
                        otherImportant: e.target.value,
                      })
                    }
                    className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                  />
                </div>
              )}
            </form>
          </div>
        )}

        {currentStep === 2 && userResponses.showConditionalSteps && (
          <div className="bg-white mb-20 p-6 mt-4">
            <div className="flex items-center mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                03
              </span>
              <h2 className="text-3xl text-gray-900">What</h2>
            </div>
            <form>
              {[
                "What's The Best Time For Me?",
                "What's The Best House For Me?",
                "What Kind Of Rates Can I Get With My Profile?",
                "What Are My Alternatives Given My Hesitations?",
                "Others",
              ].map((option, index) => (
                <label key={index} className="block text-lg text-gray-700 mt-3">
                  <input
                    type="checkbox"
                    name="what"
                    value={option}
                    onChange={(e) => handleMultiSelect("what", e.target.value)}
                    className="form-checkbox h-5 w-5 text-pink-600 focus:ring-pink-500"
                  />
                  <span className="ml-4">{option}</span>
                </label>
              ))}
              {userResponses.what && userResponses.what.includes("Others") && (
                <div className="mt-4">
                  <label className="block text-lg text-gray-900">Others</label>
                  <input
                    type="text"
                    className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-pink-500 focus:border-pink-500"
                    onChange={(e) => handleSelect("otherWhat", e.target.value)}
                  />
                </div>
              )}
            </form>
          </div>
        )}

        {currentStep === 3 && (
          <div className="bg-white mb-8 p-6 mt-8">
            <div className="flex items-center mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                04
              </span>
              <h2 className="text-3xl text-gray-900">
                {" "}
                What Are Your Preferences?
              </h2>
            </div>
            <form>
              <div className="mb-6">
                <h3 className="text-xl font-bold mb-4">Bedrooms</h3>
                <div className="flex space-x-2 mb-2">
                  {["Any", "1+", "2+", "3+", "4+", "5+"].map((label, index) => (
                    <label key={index} className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="bedrooms"
                        value={label}
                        className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
                        onChange={(e) =>
                          handleSelect("bedrooms", e.target.value)
                        }
                      />
                      <span className="text-gray-700">{label}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="mb-8">
                <h3 className="text-xl font-bold mb-4">Bathrooms</h3>
                <div className="flex space-x-2 mb-2">
                  {["Any", "1+", "2+", "3+", "4+", "5+"].map((label, index) => (
                    <label key={index} className="flex items-center space-x-2">
                      <input
                        type="radio"
                        name="bathrooms"
                        value={label}
                        className="form-radio h-5 w-5 text-pink-600 focus:ring-pink-500"
                        onChange={(e) =>
                          handleSelect("bathrooms", e.target.value)
                        }
                      />
                      <span className="text-gray-700">{label}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="mt-4 mb-6">
                <h3 className="text-xl font-bold mb-2">SQFT</h3>
                <input
                  type="range"
                  min="0"
                  max="10000"
                  step="100"
                  className="slider w-full h-2 bg-pink-200 rounded-lg appearance-none cursor-pointer"
                  value={userResponses.sqft}
                  onChange={(e) => handleSelect("sqft", e.target.value)}
                />
              </div>
              <div className="text-center text-lg font-semibold text-gray-900">
                {userResponses.sqft} SQFT
              </div>
              <div className="flex justify-between text-gray-900">
                <span>Minimum</span>
                {/* <span>No min</span>
        <span>Maximum</span> */}
                <span>Maximum</span>
              </div>
            </form>
          </div>
        )}

        {currentStep === 4 && (
          <div className="bg-white mb-16 p-6 mt-8">
            <div className="flex items-center gap-2 mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                05
              </span>
              <h2 className="text-3xl text-gray-900">Property Details</h2>
            </div>
            <form>
              <div className="mb-4">
                <h3 className="text-xl font-bold mb-2">Property Condition</h3>
                <select
                  onChange={(e) =>
                    handleDropdownChange(e, "propertyConditions")
                  }
                  className="dropdown"
                >
                  <option value="">Select 1 or more</option>
                  {propertyOptions.conditions.map((condition) => (
                    <option key={condition} value={condition}>
                      {condition}
                    </option>
                  ))}
                </select>
                <div className="tags">
                  {userResponses.propertyConditions.map((condition, index) => (
                    <span key={index} className="tag">
                      {condition}{" "}
                      <span
                        className="remove-tag"
                        onClick={() =>
                          handleMultiSelect("propertyConditions", condition)
                        }
                      >
                        x
                      </span>
                    </span>
                  ))}
                </div>
              </div>
              <div className="mb-6">
                <h3 className="text-xl font-bold mb-2">Property Type</h3>
                <select
                  onChange={(e) => handleDropdownChange(e, "propertyTypes")}
                  className="dropdown"
                >
                  <option value="">Select 1 or more</option>
                  {propertyOptions.types.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <div className="tags">
                  {userResponses.propertyTypes.map((type, index) => (
                    <span key={index} className="tag">
                      {type}{" "}
                      <span
                        className="remove-tag"
                        onClick={() => handleMultiSelect("propertyTypes", type)}
                      >
                        x
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            </form>
          </div>
        )}

        {currentStep === 5 && (
          <div className="bg-white mb-32 p-6 mt-8">
            <div className="flex items-center gap-2 mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                06
              </span>
              <h2 className="text-3xl text-gray-900">
                What Are Your Price Preferences?
              </h2>
            </div>
            <form>
              <div className="number-input-container">
                <label className="text-xl ml-16 mt-4">
                  Enter your Home Price:{" "}
                </label>
                <input
                  type="number"
                  min="10000"
                  max="1000000"
                  step="1000"
                  value={userResponses.homeValue}
                  onChange={(e) =>
                    setUserResponses({
                      ...userResponses,
                      homeValue: e.target.value,
                    })
                  }
                  className="number-input"
                  style={{
                    padding: "10px",
                    fontSize: "1.25rem",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    marginLeft: "10px",
                  }}
                />
                <div className="mt-32 price-display text-xl">
                  Approximate Home Price:{" "}
                  <span className="text-pink-600 font-bold text-2xl">
                    ${Number(userResponses.homeValue).toLocaleString()}
                  </span>
                </div>
              </div>
            </form>
          </div>
        )}

        {currentStep === 6 && (
          <div className="bg-white p-6 mt-4">
            <div className="flex items-center gap-2 mb-4">
              <span className="flex items-center justify-center h-12 w-12 rounded-full bg-black text-white text-2xl font-bold">
                07
              </span>
              <h2 className="text-3xl text-gray-900">
                Congratulation! You're qualified for a cashback!
              </h2>
            </div>
            <form>
              <div className="mb-4">
                <label className="block font-bold mb-1 text-lg">
                  Your Home Value:
                </label>
                <input
                  type="number"
                  value={userResponses.homeValue}
                  onChange={(e) =>
                    setUserResponses({
                      ...userResponses,
                      homeValue: parseInt(e.target.value) || 0,
                    })
                  }
                  className="w-full p-2 border rounded text-lg"
                />
              </div>

              <div className="bg-blue-600 text-white p-6">
                <h2 className="text-2xl font-bold mb-4">
                  You'll be saving: ${userResponses.homeValue * 0.02}
                </h2>

                <div className="mb-4">
                  <p className="font-semibold">HOME agents</p>
                  <div className="bg-green-500 w-full flex justify-between items-center px-4 py-2 rounded">
                    <span>Lower 1% commissions</span>
                    <span className="font-bold">
                      ${(userResponses.homeValue * 0.01).toLocaleString()}
                    </span>
                  </div>
                </div>

                <div className="mb-4">
                  <p className="font-semibold">Other agents</p>
                  <div className="bg-red-500 w-full flex justify-between items-center px-4 py-2 rounded">
                    <span>Higher 3% commissions</span>
                    <span className="font-bold">
                      ${(userResponses.homeValue * 0.03).toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}

        <Navigation
          onBack={goBack}
          onNext={goNext}
          onScheduleCall={handleScheduleCall}
          canGoBack={currentStep > 0}
          finalStep={
            currentStep === 6 ||
            (userResponses.showConditionalSteps && currentStep === 2)
          }
          isSelectionMade={isNextEnabled}
          currentStep={currentStep}
          isNextEnabled={isNextEnabled} // Ensure this is calculated based on form validation logic
          showConditionalSteps={userResponses.showConditionalSteps}
        />
      </div>

      <div className="w-1/3 hidden md:block w-1/3">
        <img
          src={stepImages[currentStep]}
          alt={`Step ${currentStep + 1}`}
          className="object-cover h-full w-3/7"
        />
      </div>
    </div>
  );
};

export default CalculatorFormBuyer;
