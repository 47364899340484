import React from "react";
import HeroSection from "../components/EquityLanding/HeroSection";
import HowItWorks from "../components/EquityLanding/HowItWorks";
import PriceCalculator from "../components/EquityLanding/PriceCalculator";
import StandsOut from "../components/EquityLanding/StandsOut";
import NeedFromUs from "../components/EquityLanding/NeedFromUs";
// import Testimonials from "../components/HomePage/TestimonialSlider";
import FAQSection from "../components/EquityLanding/FAQSection";
// import ToolsResources from "../components/HomePage/ToolsCard";
// import Footer from "../components/HomePage/Footer";
import Footer from "../components/Footer";
import Header from "../components/HomePage/Navbar";
import CalendyContact from "../components/EquityLanding/Calendy";
// import { testimonialsBorrow } from "../utils";
import LogoSection from "../components/HomePage/LogoSection";
import TestiMonial from "../components/HomePage/TestiMonial";

const EquityLandingPage = () => {
  return (
    <div className="bg-white overflow-x-hidden sm:overflow-x-auto">
      <Header />
      <HeroSection />
      <LogoSection />
      <HowItWorks />
      <StandsOut />
      <PriceCalculator />
      <TestiMonial />
      {/* <Testimonials testimonials={testimonialsBorrow} /> */}
      {/* <ToolsResources /> */}
      <CalendyContact />
      <FAQSection />
      <NeedFromUs />
      <Footer />
    </div>
  );
};

export default EquityLandingPage;
