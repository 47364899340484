import React, { useState, useEffect, useRef } from "react";
import ProgressIndicator from "../components/CashOut/ProgressIndicator";
// import Question from "../components/CashOut/Question";
import Navigation from "../components/CashOut/Navigation";
// import { QuestionType } from "../components/CashOut/Question"; // Ensure this is the correct path
import EligibilityResult from "../components/CashOut/EligibilityResult";
import OtherStateFormModal from "../components/EquityLanding/OtherStateModal";
import EligibilityModal from "../components/EquityLanding/EligibleModal";
import IneligibilityModal from "../components/EquityLanding/InEligibleModal";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { useDispatch, useSelector } from 'react-redux';
import { setAddress } from '../../src/store/stringSlice';

const libraries = ["places"];

// 240k,3m
const CalculatorForm = () => {
  const steps = [
    "Purpose Of Loan",
    "Location Check",
    "Your Home Value",
    "Home Ownership",
    "Equity Estimate",
    "Credit Score",
    "Qualification",
    "propertyType",
    "propertyDetails",
  ]; // Add all steps
  const GOOGLE_MAPS_API_KEY = "AIzaSyBOuPOv0Ztq0yOARwDuxh8GNikEULFvGuo";
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [showOtherStateModal, setShowOtherStateModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [userResponses, setUserResponses] = useState({
    loanPurpose: "",
    otherLoanPurpose: "",
    cashOutAmount: "",
    address: "",
    homeValue: "", // Previously homeValue state
    mortgage: 0, // Previously homeOwnership state
    equityPercentage: 100, // Previously equityPercentage state
    cashOutAmountPercentage: 0,
    creditScore: "", // Previously creditScore state
    name: "", // Added name field
    email: "", // Added email field
    phone: "",
    propertyType: "",
    propertyDetails: "",
    qualified: "",
    Date: new Date().toLocaleDateString("en-US"),
  });

  // Add state to track address selection
  const [isAddressSelected, setIsAddressSelected] = useState(false);


  const showEligibilityResult = currentStep === 8;
  const userCount = 13473;
  const [showEligibilityModal, setShowEligibilityModal] = useState(false);
  const [showIneligibilityModal, setShowIneligibilityModal] = useState(false);
  const bottomRef = useRef(null);
  const stateMapping = {
    Arizona: "AZ",
    California: "CA",
    Colorado: "CO",
    Florida: "FL",
    Massachusetts: "MA",
    "North Carolina": "NC",
    Oregon: "OR",
    Virginia: "VA",
    "Washington DC": "DC",
    Washington: "WA",
  };
  

  const allowedStates = Object.keys(stateMapping).concat(
    Object.values(stateMapping)
  );

    // Get the address from the global state
    const address = useSelector((state) => state.address);
    const dispatch = useDispatch();
  
    // Update local state when the Redux state changes
    useEffect(() => {
      if (address !== userResponses.address) {
        setUserResponses((prevResponses) => ({
          ...prevResponses,
          address: address
        }));
      }
    }, [address]);



  // Validation functions for each step
  const validations = {
    0: () => userResponses.address,
    1: () => userResponses.propertyType,
    2: () => userResponses.propertyType,
    3: () => userResponses.homeValue > 0,
    4: () => userResponses.mortgage,
    5: () => userResponses.cashOutAmountPercentage,
    6: () => userResponses.loanPurpose,
    7: () => userResponses.creditScore,
    8: () => userResponses.creditScore,
  };

  // Function to check if the current step is valid
  const isValidStep = (step) => {
    return validations[step]();
  };

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [userResponses.loanPurpose]);

  useEffect(() => {
    setIsNextEnabled(isValidStep(currentStep));
  }, [userResponses, currentStep, isAddressSelected]);

  useEffect(() => {
    setIsComplete(checkAllFieldsFilled());
  }, [userResponses]);

  const isSelectionMade =
    userResponses.creditScore &&
    userResponses.homeValue &&
    userResponses.propertyType;

  const stepImages = [
    "https://st.hzcdn.com/simgs/pictures/exteriors/contemporary-open-home-clearovations-img~c2f196810890c514_14-4417-1-bd2ff3a.jpg",
    "https://st4.depositphotos.com/13324256/26353/i/450/depositphotos_263536652-stock-photo-silver-golden-coins-green-leaves.jpg",
    "https://st.hzcdn.com/simgs/pictures/exteriors/contemporary-open-home-clearovations-img~c2f196810890c514_14-4417-1-bd2ff3a.jpg",
    "https://st4.depositphotos.com/13324256/26353/i/450/depositphotos_263536652-stock-photo-silver-golden-coins-green-leaves.jpg",
    "https://st.hzcdn.com/simgs/pictures/exteriors/contemporary-open-home-clearovations-img~c2f196810890c514_14-4417-1-bd2ff3a.jpg",
    "https://st4.depositphotos.com/13324256/26353/i/450/depositphotos_263536652-stock-photo-silver-golden-coins-green-leaves.jpg",
    "https://st.hzcdn.com/simgs/pictures/exteriors/contemporary-open-home-clearovations-img~c2f196810890c514_14-4417-1-bd2ff3a.jpg",
    "https://st4.depositphotos.com/13324256/26353/i/450/depositphotos_263536652-stock-photo-silver-golden-coins-green-leaves.jpg",
    "https://st.hzcdn.com/simgs/pictures/exteriors/contemporary-open-home-clearovations-img~c2f196810890c514_14-4417-1-bd2ff3a.jpg",
    "https://st4.depositphotos.com/13324256/26353/i/450/depositphotos_263536652-stock-photo-silver-golden-coins-green-leaves.jpg",
    "https://st.hzcdn.com/simgs/pictures/exteriors/contemporary-open-home-clearovations-img~c2f196810890c514_14-4417-1-bd2ff3a.jpg",
    // ...add paths for all steps
  ];

  const handleSelect = (field, value) => {
    setUserResponses((prevResponses) => {
      const updatedState = {
        ...prevResponses,
        [field]: value,
      };

       // If the address is being updated, dispatch to Redux
    if (field === 'address') {
      dispatch(setAddress(value));
    }

      if (field === "creditScore") {
        const creditScore = parseInt(value);
        const address = prevResponses.address;
        const stateRegex = new RegExp(
          `\\b(${allowedStates.join("|")})\\b`,
          "i"
        );
        const stateInAddress = stateRegex.test(address);

        updatedState.qualified =
          !stateInAddress || creditScore < 650 ? "no" : "yes";
      }

      return updatedState;
    });
  };

  const checkAllFieldsFilled = () => {
    // Assuming these are the fields needed to enable the button
    return (
      userResponses.loanPurpose &&
      userResponses.address &&
      userResponses.homeValue &&
      userResponses.mortgage &&
      userResponses.equityPercentage > 0 &&
      userResponses.creditScore
    );
  };

  const checkQualification = () => {
    if (parseInt(userResponses.creditScore) < 600) {
      console.log("User does not qualify");
      return false;
    }

    // Ensure we're parsing the user input correctly
    const homeValueInDollars = parseFloat(
      userResponses.homeValue.replace(/[^0-9.]+/g, "")
    );
    const equityPercentage = parseFloat(userResponses.equityPercentage) / 100; // Ensure this is a decimal

    // Calculate the amount eligible for cashout
    const cashoutAmount = homeValueInDollars * equityPercentage;

    setUserResponses((prev) => ({
      ...prev,
      qualifiedAmount: cashoutAmount, // Update the qualified amount to be the calculated cashout
    }));

    console.log("User qualifies with a cashout amount of:", cashoutAmount);
    return true;
  };

  const goNext = async () => {
    if (currentStep === 7) {
      const address = userResponses.address;
      const stateRegex = new RegExp(`\\b(${allowedStates.join("|")})\\b`, "i");
      const stateInAddress = stateRegex.test(address);
      const creditScore = parseInt(userResponses.creditScore);

      if (!stateInAddress) {
        setShowOtherStateModal(true);
        return;
      }

      if (creditScore < 650) {
        setShowIneligibilityModal(true);
      } else {
        if (checkQualification()) {
          setShowEligibilityModal(true);
        } else {
          setShowIneligibilityModal(true);
        }
      }
    } else if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleEligibleModalClose = async () => {
    setShowEligibilityModal(false);

    setCurrentStep(8); // Directly set to the 6th step

    await sendDataToWebhook();
  };

  const handleInEligibleModalClose = async () => {
    setShowIneligibilityModal(false);
    await sendDataToWebhook();
    window.location.href = "/cashout";
  };

  const handleModalVisibility = async () => {
    setShowOtherStateModal(false);
    await sendDataToWebhook();
    window.location.href = "/cashout";
  };

  const sendDataToWebhook = async () => {
    try {
      const response = await fetch(
        "https://hooks.zapier.com/hooks/catch/10869005/2yw1g63/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userResponses),
          mode: "no-cors",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      console.log("Data sent successfully to the webhook.");
    } catch (error) {
      // console.error("Error sending data to the webhook:", error);
    }
  };

  // Helper functions
  const updateUserResponses = (field, value) => {
    setUserResponses((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const calculateEquityPercentage = (homeValue, mortgage) => {
    // Convert strings to numbers and check for validity
    const numericHomeValue = parseFloat(homeValue);
    const numericMortgage = parseFloat(mortgage);

    if (
      isNaN(numericHomeValue) ||
      isNaN(numericMortgage) ||
      numericHomeValue <= 0
    ) {
      return 0; // Return 0% if inputs are not valid numbers or home value is zero or less
    }

    const equityValue = numericHomeValue - numericMortgage;
    return (equityValue / numericHomeValue) * 100; // Calculate and return percentage
  };

  // Now, update the onChange function
  const handleMortgageChange = (value) => {
    // Extract numeric part for state storage and logic
    const numericValue = value.replace(/[^\d.]/g, "");

    // Update the state with the numeric value for calculations
    updateUserResponses("mortgage", numericValue);

    // Calculate the new equity percentage using the numeric value
    const equityPercentage = calculateEquityPercentage(
      parseFloat(userResponses.homeValue), // Convert to ensure calculation precision
      parseFloat(numericValue)
    );
    updateUserResponses("equityPercentage", equityPercentage);
  };

  const goBack = () => setCurrentStep((prev) => prev - 1);

  // Update the slider and cashout percentage and amount in the state
  const handleSliderChange = (value) => {
    const homeValue = parseFloat(userResponses.homeValue) || 0;
    const maxCashOutAmount = (homeValue * 15) / 100;
    const newCashOutAmount = (value / 100) * homeValue;

    if (newCashOutAmount <= maxCashOutAmount) {
      setUserResponses((prevResponses) => ({
        ...prevResponses,
        cashOutAmountPercentage: value,
        cashOutAmount: newCashOutAmount,
      }));
    } else {
      setUserResponses((prevResponses) => ({
        ...prevResponses,
        cashOutAmountPercentage: 15,
        cashOutAmount: maxCashOutAmount,
      }));
    }
  };

  const handleCashOutAmountChange = (value) => {
    const homeValue = parseFloat(userResponses.homeValue) || 0;
    const numericValue = parseFloat(value.replace(/[^\d.]/g, "")) || 0;
    const maxCashOutAmount = (homeValue * 15) / 100;

    if (numericValue <= maxCashOutAmount) {
      const newCashOutAmountPercentage = (numericValue / homeValue) * 100;
      setUserResponses((prevResponses) => ({
        ...prevResponses,
        cashOutAmount: parseFloat(numericValue.toFixed(2)),
        cashOutAmountPercentage: parseFloat(newCashOutAmountPercentage),
      }));
    } else {
      setUserResponses((prevResponses) => ({
        ...prevResponses,
        cashOutAmount: parseFloat(maxCashOutAmount.toFixed(2)),
        cashOutAmountPercentage: 15,
      }));
    }
  };

  // const handleModalVisibility = (isVisible) => {
  //   setShowOtherStateModal(isVisible);
  //   const body = document.querySelector("body");
  //   if (isVisible) {
  //     body.classList.add("body-blur");
  //   } else {
  //     body.classList.remove("body-blur");
  //   }
  // };

  const formatCurrency = (value) => {
    // Ensure the value is a number; handle empty or non-numeric gracefully
    if (!value && value !== 0) return "$0";

    // Convert to a number for accurate formatting
    const numericValue = parseFloat(value);

    // If the value isn't a number or is zero, return "$0"
    if (isNaN(numericValue)) {
      return "$0";
    }

    // Format normally for valid numbers
    return `$${numericValue.toLocaleString()}`;
  };

  const investmentPurposeOptions = [
    {
      label: "Debt Payoff",
      value: "debt_payoff",
      description:
        "Pay off loans, credit cards, or other debts so I can work toward financial freedom.",
    },
    {
      label: "Portfolio Diversification",
      value: "portfolio_diversification",
      description: "Invest in other assets (stocks, bonds, mutual funds, etc.)",
    },
    {
      label: "Home Improvement",
      value: "home_improvement",
      description: "Make home improvements, repairs, and/or renovations.",
    },
    {
      label: "Education",
      value: "education",
      description: "Access money for tuition or student loan payments.",
    },
    {
      label: "Investment Property or Vacation Home",
      value: "investment_property_vacation_home",
      description: "Purchase a rental, investment, or vacation property.",
    },
    {
      label: "Small Business Funding",
      value: "small_business_funding",
      description: "Start or grow my small business.",
    },
    {
      label: "Life Events or Emergency Funding",
      value: "life_events_emergency_funding",
      description:
        "Pull together funds for emergencies or an unexpected life event.",
    },
    {
      label: "Retirement",
      value: "retirement",
      description: "Live a more financially comfortable life in retirement.",
    },
    {
      label: "Divorce Transition",
      value: "divorce_transition",
      description: "Secure funds to help navigate a divorce.",
    },
    {
      label: "Family Expansion",
      value: "family_expansion",
      description: "Obtain money to support my growing family.",
    },
    {
      label: "Others",
      value: "others",
      desc: "Specify your own purpose",
    }, // New option for others
  ];

  // const handleEligibleModalClose = (advance = false) => {
  //   setShowEligibilityModal(false);
  //   if (advance) {
  //     setCurrentStep(8); // Directly set to the 6th step
  //   }
  // };

  // const handleInEligibleModalClose = () => {
  //   setShowEligibilityModal(false);
  //   window.location.href = "/borrow";
  // };

  const autocompleteRef = useRef(null);

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    handleSelect("address", place.formatted_address);
    setIsAddressSelected(true);
  };

  return (
    <div className="relative flex w-full min-h-screen ">
      <div>
        {showOtherStateModal && (
          <div className="modal-backdrop">
            <div className="modal-dialog">
              <OtherStateFormModal
                isOpen={showOtherStateModal}
                onClose={() => handleModalVisibility(false)}
                userResponses={userResponses}
                updateUserResponses={handleSelect}
              />
            </div>
          </div>
        )}
        {showEligibilityModal && (
          <div className="modal-backdrop">
            <div className="modal-dialog">
              <EligibilityModal
                isOpen={showEligibilityModal}
                onClose={() => handleEligibleModalClose(true)}
                userResponses={userResponses}
                updateUserResponses={handleSelect} // Pass handleChange as a prop
              />{" "}
            </div>
          </div>
        )}
        {showIneligibilityModal && (
          <IneligibilityModal
            isOpen={showIneligibilityModal}
            onClose={() => handleInEligibleModalClose(true)}
            userResponses={userResponses}
            updateUserResponses={handleSelect} // Pass handleChange as a prop
          />
        )}
      </div>

      <div className="w-full md:w-2/3 p-4 overflow-auto">
        <ProgressIndicator steps={steps} currentStep={currentStep} />
        {/* Conditional rendering based on currentStep */}

        {currentStep === 0 && (
          <div className="bg-white mb-72 p-6 rounded-lg mt-8 ml-4">
            <div className="flex items-center mb-8 gap-2">
              <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
                01
              </span>
              <h2 className="text-3xl text-gray-900">Enter your address</h2>
            </div>
            {/* <p className="text-black">
              This helps us make sure we're available in your area.
            </p> */}
            <LoadScript
              googleMapsApiKey={GOOGLE_MAPS_API_KEY}
              libraries={libraries}
              loadingElement={<div>Loading...</div>}
              options={{
                defer: true,
                async: true,
              }}
            >
              <Autocomplete
                onLoad={(autocomplete) => {
                  autocompleteRef.current = autocomplete;
                }}
                onPlaceChanged={handlePlaceSelect}
              >
                <input
                  type="text"
                  placeholder="Is your home eligible for a home equity investment? Let's find out!"
                  className="w-full px-4 py-2 mt-4 leading-tight text-black bg-transparent shadow-none focus:outline-none focus:bg-transparent placeholder-gray-500"
                  style={{ borderBottom: "3px solid black" }}
                  value={userResponses.address}
                  onChange={(e) => handleSelect("address", e.target.value)}
                />
              </Autocomplete>
            </LoadScript>
          </div>
        )}

        {currentStep === 1 && (
          <div className="bg-white p-6 mt-8 mb-20 rounded-lg">
            <div className="flex items-center mb-4">
              <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
                02
              </span>
              <h2 className="text-3xl text-gray-900">What type of Property</h2>
            </div>
            <p className="mt-8 mb-4">
              How do you use the property at{" "}
              <span className="font-bold">{userResponses.address}</span>?
            </p>
            <form>
              <fieldset>
                <legend className="sr-only">Property Type</legend>
                <div className="flex flex-wrap -mx-2">
                  {[
                    { label: "It's my primary residence", value: "primary" },
                    { label: "It's a vacation home", value: "vacation" },
                    { label: "I rent it out", value: "rental" },
                  ].map((option, index) => (
                    <label
                      key={index}
                      className={`w-full md:w-1/2 p-4 mb-4 md:mb-4 md:mr-4 text-xl border rounded-lg cursor-pointer hover:bg-gray-100 ${
                        userResponses.propertyType === option.value
                          ? "border-pink-600 bg-pink-50"
                          : "border-gray-300"
                      }`}
                    >
                      <input
                        type="radio"
                        name="propertyType"
                        value={option.value}
                        checked={userResponses.propertyType === option.value}
                        onChange={(e) =>
                          handleSelect("propertyType", e.target.value)
                        }
                        className="hidden"
                      />
                      <div className="flex items-center">
                        <span className="text-gray-700">{option.label}</span>
                      </div>
                    </label>
                  ))}
                </div>
              </fieldset>
            </form>
          </div>
        )}

        {currentStep === 2 && (
          <div className="bg-white p-6 mb-8 mt-8 rounded-lg">
            <div className="flex items-center">
              <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
                03
              </span>
              <h2 className="text-3xl text-gray-900">
                Tell us about your property
              </h2>
            </div>
            <p className="mt-4 mb-4">
              What type of property is{" "}
              <span className="font-bold">{userResponses.address}</span>?
            </p>
            <form>
              <fieldset>
                <legend className="sr-only">Property Details</legend>
                <div className="flex flex-wrap -mx-2">
                  {[
                    {
                      label: "Single-Family Home",
                      value: "single_family",
                      description: "",
                    },
                    {
                      label: "Multi-Family Home",
                      value: "multi_family",
                      description: "",
                    },
                    {
                      label: "Condo, Townhouse or Apartment",
                      value: "condo_townhouse_apartment",
                      description:
                        "A unit within a larger building or complex, offering shared amenities.",
                    },
                    {
                      label: "Co-op",
                      value: "co_op",
                      description:
                        "A property owned by a corporation, where you own shares in the corporation.",
                    },
                    {
                      label: "Manufactured Home",
                      value: "manufactured_home",
                      description:
                        "A home permanently affixed to a foundation or utilities, built off-site.",
                    },
                    {
                      label: "Mobile Home",
                      value: "mobile_home",
                      description:
                        "A home NOT permanently affixed to a foundation or utilities, built off-site.",
                    },
                  ].map((option, index) => (
                    <label
                      key={index}
                      className={`w-full md:w-[48%] mx-2 mb-4 text-xl p-2 border rounded-lg cursor-pointer hover:bg-gray-100 ${
                        userResponses.propertyDetails === option.value
                          ? "border-pink-600 bg-pink-50"
                          : "border-gray-300"
                      }`}
                    >
                      <input
                        type="radio"
                        name="propertyDetails"
                        value={option.value}
                        checked={userResponses.propertyDetails === option.value}
                        onChange={(e) =>
                          handleSelect("propertyDetails", e.target.value)
                        }
                        className="hidden"
                      />
                      <div className="flex flex-col">
                        <span className="text-gray-700">{option.label}</span>
                        {option.description && (
                          <p className="text-gray-500 mt-2 text-sm">
                            {option.description}
                          </p>
                        )}
                      </div>
                    </label>
                  ))}
                </div>
              </fieldset>
            </form>
          </div>
        )}

        {currentStep === 3 && (
          <div className="bg-white p-6 mb-8 mt-4 rounded-lg ">
            <div className="flex items-center mb-4">
              {/* Include the ProgressIndicator component here */}
            </div>
            <div className="mb-4">
              <div className="flex items-center gap-2">
                <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
                  04
                </span>
                <h2 className="text-3xl text-gray-900">
                  Find out your Home's value
                </h2>
              </div>
              <p className="mt-8">
                Sharing personal details can feel daunting, but it helps us
                provide you with the best possible assistance.
                <br />
                Could you select a range for your home's value?
              </p>
              <form>
                <div className="mt-8 mb-2">Home Value</div>
                <input
                  type="text"
                  name="homeValue"
                  placeholder="Please Enter your home value"
                  value={formatCurrency(userResponses.homeValue)} // Prepend a $ to the value
                  onChange={(e) =>
                    handleSelect(
                      "homeValue",
                      e.target.value.replace(/[^\d.]/g, "")
                    )
                  }
                  className=" block w-full p-3 border-b  border-black"
                />
                <div className="bg-white p-5">
                  <p className="mt-4 text-2xl text-gray-900">
                    Curious about your Home's value
                  </p>
                  <a
                    href="https://www.zillow.com"
                    target="_blank"
                    rel="noreferrer"
                    className="font-bold text-2xl text-pink-500 hover:text-pink-600"
                  >
                    Home value with Zillow→
                  </a>
                </div>
              </form>
            </div>
          </div>
        )}

        {currentStep === 4 && (
          <div className="bg-white mb-40 p-6 mt-8 rounded-lg">
            <div className="mb-4">
              <div className="flex items-center gap-2">
                <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
                  05
                </span>
                <h2 className="text-3xl text-gray-900">
                  Does your property have any outstanding mortgages?
                </h2>
              </div>
              <p className="mt-8">
                If yes, kindly share the total balance. If your home is fully
                owned, you can skip ahead.
              </p>
            </div>
            <form>
              mortgage
              <input
                type="text" // Use 'text' to allow formatted input
                name="mortgage"
                placeholder="Please enter your Mortgage"
                value={formatCurrency(userResponses.mortgage)}
                onChange={(e) => handleMortgageChange(e.target.value)}
                className="mt-4 block w-full p-3 border border-gray-300 rounded-lg"
              />
            </form>
            <p className="text-xl mt-4">
              You Own{" "}
              <span className="font-bold">
                {userResponses.equityPercentage.toFixed(2)}%
              </span>{" "}
              Of Your Home
            </p>
          </div>
        )}

        {currentStep === 5 && (
          <div className="bg-white p-6 mt-8">
            <div className="mb-4 ml-4">
              <div className="flex items-center gap-2">
                <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
                  06
                </span>
                <h2 className="text-3xl text-gray-900">Cashout Estimate</h2>
              </div>
              <p className="text-gray-600 mt-8 mb-14">
                Based on your slider selection, we'll estimate the valuation of
                your home equity. At Home.LLC, we usually work with a maximum of
                15% of your equity.
              </p>
            </div>
            <div className="ml-4 mr-4 mt-4">
              <input
                id="equity-estimate-slider"
                type="range"
                min="1"
                max="15"
                step="0.1"
                value={userResponses.cashOutAmountPercentage}
                onChange={(e) => handleSliderChange(parseFloat(e.target.value))}
                className="slider-thumb w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              <div className="flex justify-between text-gray-700 text-xl mb-2">
                <span>1%</span>
                <span>15%</span>
              </div>
              <div className="mt-16 mb-16">
                <label
                  htmlFor="cash-out-amount"
                  className="text-gray-700 text-2xl"
                >
                  You can cash out:{" "}
                  <input
  id="cash-out-amount"
  type="text"
  value={formatCurrency(userResponses.cashOutAmount)}
  onChange={(e) => handleCashOutAmountChange(e.target.value)}
  className="font-bold text-pink-500 text-3xl sm:text-2xl sm:mb-4 md:text-3xl bg-transparent border-b border-black p-2 m-0 focus:outline-none w-full sm:w-auto"
/>

                  ({userResponses.cashOutAmountPercentage.toFixed(2)}%)
                </label>
              </div>
            </div>
          </div>
        )}

        {currentStep === 6 && (
          <div className="bg-white p-6 mt-8 rounded-lg">
            <div className="mb-4">
              <div className="flex items-center gap-2">
                <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
                  07
                </span>
                <h2 className="text-3xl text-gray-900">
                  Why are you considering a home equity investment?
                </h2>
              </div>
              <p className="mt-8">
                {/* Welcome to Home.LLC! We're here to make your financial journey
              smooth sailing.
              <br /> First things first, what's on your mind for the funds? */}
              </p>
            </div>
            <form>
              <fieldset>
                <legend className="sr-only">Purpose of Investment</legend>
                <div className="flex flex-wrap -mx-2">
                  {investmentPurposeOptions.map((option, index) => (
                    <label
                      key={index}
                      className={`w-full sm:w-1/2 lg:w-1/3 px-4 mb-4 text-xl border rounded-lg cursor-pointer hover:bg-gray-100 ${
                        userResponses.loanPurpose === option.value
                          ? "border-pink-600 bg-pink-50"
                          : "border-gray-300"
                      }`}
                    >
                      <input
                        type="radio"
                        name="loanPurpose"
                        value={option.value}
                        checked={userResponses.loanPurpose === option.value}
                        onChange={(e) =>
                          handleSelect("loanPurpose", e.target.value)
                        }
                        className="hidden"
                      />
                      <div className="flex flex-col p-4">
                        <span className="text-gray-700">{option.label}</span>
                        <p className="text-gray-500 mt-2 text-sm">
                          {option.description}
                        </p>
                      </div>
                    </label>
                  ))}
                  {userResponses.loanPurpose === "others" && (
                    <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-4">
                      <input
                        type="text"
                        placeholder="Enter your specific purpose"
                        value={userResponses.otherLoanPurpose}
                        onChange={(e) =>
                          handleSelect("otherLoanPurpose", e.target.value)
                        }
                        className="block w-full p-3 border border-black rounded-lg"
                      />
                    </div>
                  )}
                </div>
              </fieldset>
            </form>
            <div ref={bottomRef} />
          </div>
        )}

        {currentStep === 7 && (
          <div className="bg-white p-6 mt-8">
            <div className="mb-4">
              <div className="flex items-center gap-2">
                <span className="flex text-2xl items-center justify-center h-12 w-12 rounded-full bg-black text-white font-bold">
                  08
                </span>
                <h2 className="text-3xl  text-gray-900">Your Credit Score</h2>
              </div>

              <p className=" mt-8">
                We appreciate your openness, understanding your finance allows
                us to offer a solution suiting your need.
                <br /> Select a range that best represents your credit score?
              </p>
            </div>
            <form>
              <fieldset>
                <legend className="sr-only">Your Credit Score</legend>
                {[
                  {
                    label: "Facing Challenges (600)",
                    value: "600",
                  },
                  {
                    label: "On The Path To Improvement (600 - 650)",
                    value: "600-650",
                  },
                  { label: "Well-Established (651 - 720)", value: "651_720" },
                  { label: "Exceptional (Above 720)", value: "above_720" },
                ].map((option, index) => (
                  <label key={index} className="block mt-4 pb-5 text-2xl">
                    <input
                      type="radio"
                      name="creditScore"
                      value={option.value}
                      onChange={(e) =>
                        handleSelect("creditScore", e.target.value)
                      }
                      className="form-radio h-5 w-5 text-pink-600"
                    />
                    <span className="ml-4 text-gray-700">{option.label}</span>
                  </label>
                ))}
              </fieldset>
            </form>
          </div>
        )}

        {showEligibilityResult && (
          <EligibilityResult
            eligibleAmount={userResponses.qualifiedAmount}
            userCount={userCount}
            userData={userResponses}
          />
        )}
        <Navigation
          onBack={goBack}
          onNext={goNext}
          canGoBack={currentStep > 0}
          finalStep={currentStep === steps.length - 1}
          isSelectionMade={isSelectionMade}
          isNextEnabled={isNextEnabled}
          currentStep={currentStep}
        />
      </div>

      <div className="hidden md:block w-1/3">
        <img
          src={stepImages[currentStep]}
          alt={`Step ${currentStep + 1}`}
          className="object-cover h-full w-3/7"
        />
      </div>
    </div>
  );
};

export default CalculatorForm;

